import * as React from 'react'

const ExplanatoryText = ({ children }) => {
  return(
    <div id="explanatory-text" className="max-w-2xl flex flex-col	mx-auto px-4">
      {children}
    </div>
  )
}

const ScrollContainer = ({ children }) => {
  return(
    <div id="scrolly-container" className="flex flex-col my-12 p4">
      {children}
    </div>
  )
}

const ScrollArticle = ({ children }) => {
  return (
    <div id="text-over-graphic" className="relative	p-0 max-w-lg my-0 mx-auto">
      {children}
    </div>
  )
}

const ScrollStep = ({ children }) => {
  return (
    <div id="first-step" className="mb-100vh mt-25vh mx-4 sm:mx-0 p-12 bg-white border border-slate-500">
      {children}
    </div>
  )
}

export { ExplanatoryText, ScrollContainer, ScrollArticle, ScrollStep }
