import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { ExplanatoryText, ScrollContainer, ScrollArticle, ScrollStep } from '../../components/scrolly'

const TheEfficiencyMyth = () => {

  return (

    <div id="jsx-parent">

      <head>
        <meta name="robots" content="noindex" />
      </head>
    
      <div id="hero" className='bg-[#000000] flex flex-col justify-center items-center'>
        <div className={`min-h-screen max-w-3xl flex flex-col justify-center px-4`}>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#fff]'>For decades, we've been told that fossil fuels are the most efficient energy source.</p>
        </div>
        <div className={`min-h-screen max-w-3xl flex flex-col justify-center px-4`}>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#fff]'>They may be bad for the planet, people's health, and even shareholders over the long run.
          But in a capitalist society, short-term efficiency is the ultimate goal, not sustainability.</p>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#fff]'>So we keep burning them.</p>
        </div>
        <div className={`min-h-screen max-w-3xl flex flex-col justify-center px-4`}>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#fff]'>But what if that story is wrong?</p>
        </div>
        <div className={`min-h-screen max-w-3xl flex flex-col justify-center px-4`}>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#888888]'>Opinion</p>
          <h1 className='mt-0 text-center text-4xl sm:text-6xl text-[#fff]'>The Efficiency Myth</h1>
          <p className='mt-2 text-center font-normal text-xl sm:text-2xl text-[#999999]'>The fossil fuel economy is inefficient and wasteful. Here's how we can fix it.</p>
        </div>
      </div>

      <div className='my-16'>
        <ExplanatoryText>
          <p>The inefficiencies begin hundreds of feet underground. Deep in the bowels of the Earth, 
            miners use fossil fuels to dig up more fossil fuels.</p> 
          <p>It takes a lot of energy to dig, drill, and blast useless rocks out of the way in order 
            to get to the stuff humans can use. Many of the machines used to dig up fossil fuels use as much 
            energy in a single day as entire neighborhoods of homes use in a year.</p>
          <p>When all's said and done, 2% of all the energy consumed in the United States is used to power these machines.</p>
          <p>But this is just the beginning of the fossil fuel journey. In order to turn oil, gas, and coal into something useful, 
            there's still a lot of work to do.</p>
        </ExplanatoryText>
      </div>

      <StaticImage 
        src="../../images/article-images/coal-mine.jpg"
      />

      <div className='my-16'>
        <ExplanatoryText>
          <p>A lump of coal isn't that useful. You can't fill your car up with crude oil. 
            And natural gas doesn't do much good out in the plains of Texas. In order to be valuable, fossil fuels must be transported, 
            processed, and then often transported again. All of this requires energy.
          </p>
          <p>1% of all energy in the United States is used to move natural gas from desolate praries to population centers. 4% of 
            the country's energy is used to refine crude oil into gasoline and diesel. Half of all trains that traverse the country 
            carry one product: coal; these trains consume another quarter of a percent of America's energy.</p>
          <p>All of this is incredibily inefficient.</p>
          <p className='text-center'>___</p>
          <p>For decades, we've known that fossil fuels cause climate change, lethal air pollution, and ecological destruction. 
            But the companies that extract, transport, and burn these fuels have told the public that we have little choice: 
            These externalities are the cost of a prosperous society.</p>
          <p>In the past this was true. It used to be easy to extract fossil fuels and hard to get energy from the sun or wind. 
            But in the last decade all of this changed. </p>
          <p>In this story I'll show New York Times readers that the fossil fuel economy is far less efficient than we've been 
            led to believe. Right now, at a time of energy insecurity and rising prices, leaders around the world are thinking 
            about how to use less energy. I'll argue that traditional energy efficiency measures, first developed in the 1970s 
            energy crisis, are insufficient and that the best way to save energy is to replace fossil fuels with renewables and 
            "electrify everything."
          </p>
          
        </ExplanatoryText>
      </div>
      

    </div>

  )
}

export default TheEfficiencyMyth